export const columnsDataCheck = [
  // {
  //   Header: "DATE",
  //   accessor: "date",
  //   label: "Data",
  // },
  {
    Header: "NAME",
    accessor: "name",
    label: "Assunto",
  },
  {
    Header: "STATUS",
    accessor: "status",
    label: "Status",
  },
];
export const columnsDataComplex = [
  // {
  //   Header: "DATE",
  //   accessor: "date",
  //   label: "Data",
  // },
  {
    Header: "NAME",
    accessor: "name",
    label: "Tópico",
  },
  {
    Header: "STATUS",
    accessor: "status",
    label: "Status",
  }
];
export const columnsDataCronogram = [
  // {
  //   Header: "DATE",
  //   accessor: "date",
  //   label: "Data",
  // },
  {
    Header: "NAME",
    accessor: "name",
    label: "Descrição",
  },
  {
    Header: "STATUS",
    accessor: "status",
    label: "Status",
  }
];
export const columnsDataProjects = [
  // {
  //   Header: "DATE",
  //   accessor: "date",
  //   label: "Data",
  // },
  {
    Header: "NAME",
    accessor: "name",
    label: "Descrição",
  },
  {
    Header: "STATUS",
    accessor: "status",
    label: "Status",
  }
];
