import React from "react";

// Chakra imports
import { Flex, Image } from "@chakra-ui/react";

// Custom components
import { HSeparator } from "components/separator/Separator";

// import logo from "assets/img/layout/logo-blue.webp";
import logo from "assets/img/layout/ima-tech-logo-blue.png";

export function SidebarBrand() {
  return (
    <Flex align='start' direction='column'>
      <Image ml="10px" className="" src={logo} width="180px" alt="Logo" />
      <HSeparator mt='20px' mb='20px' />
    </Flex>
  );
}

export default SidebarBrand;
