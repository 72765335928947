import React, { useEffect, useState } from "react";
import { useHistory, NavLink } from "react-router-dom";
import axios from "axios";
import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Text,
  Select,
  SimpleGrid,
  useColorModeValue,
  Progress,
} from "@chakra-ui/react";
// Assets
import Usa from "assets/img/dashboards/usa.png";
// Custom components
import MiniCalendar from "components/calendar/MiniCalendar";
import MiniStatistics from "components/card/MiniStatistics";
import Card from "components/card/Card.js";
import IconBox from "components/icons/IconBox";
import { FaUsers } from "react-icons/fa";
import {
  MdAddTask,
  MdAttachMoney,
  MdBarChart,
  MdFileCopy,
  MdOutlineWork,
  MdSchool,
  MdLineWeight,
} from "react-icons/md";
import CheckTable from "views/admin/default/components/CheckTable";
import ComplexTable from "views/admin/default/components/ComplexTable";
import DailyTraffic from "views/admin/default/components/DailyTraffic";
import DailyTrafficFormation from "views/admin/default/components/DailyTrafficFormation";
import PieCard from "views/admin/default/components/PieCard";
import PieCardGender from "views/admin/default/components/PieCardGender";
import DailyTrafficAge from "views/admin/default/components/DailyTrafficAge";
import PieCardPCD from "views/admin/default/components/PieCardPCD";
import Tasks from "views/admin/default/components/Tasks";
import TotalSpent from "views/admin/default/components/TotalSpent";
import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
import {
  columnsDataCheck,
  columnsDataComplex,
  columnsDataCronogram,
  columnsDataProjects,
} from "views/admin/default/variables/columnsData";
import tableDataCheck from "views/admin/default/variables/tableDataCheck.json";
import tableDataComplex from "views/admin/default/variables/tableDataComplex.json";
import tableDataCronogram from "views/admin/default/variables/tableDataCronogram.json";
import tableDataProjects from "views/admin/default/variables/tableDataProjects.json";

import { tableColumnsTeachers } from "views/admin/programa/variables/tableColumnsTeachers";

import { API_SERVER } from "config/constant";
import Banner from "./components/Banner";
import Teachers from "./components/Teachers";
import Cronogram from "./components/Cronogram";
import ComplexTableProjects from "./components/ComplexTableProjects";

export default function UserReports() {
  const history = useHistory();

  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";

  const [loading, setLoading] = useState(false);
  const [loadingProgram, setLoadingProgram] = useState(false);
  const [program, setProgram] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [teachers, setTeachers] = useState([]);

  const programId = 2;

  const users = require("../../../data/users.json");
  const companySlug = localStorage.getItem('companySlug');

  useEffect(() => {
    const findUserByCompanie = users?.find((data) => data?.company == companySlug);

    if (!findUserByCompanie) {
      return history.push("/auth/sign-out");
    }
  }, []);

  const dataUser = users?.find((data) => data?.company == companySlug);

  // useEffect(() => {    
  //   const getProgramById = async (programId) => {
  //     if (loading) {
  //       return false;
  //     }

  //     setLoading(true);

  //     const { data } = await axios.get(`${API_SERVER}dashboard/program/${programId}`);

  //     const { program, companies, teachers, classLeadersGroups } = data?.dataProgram;
      
  //     program[0] && setProgram(program[0]);
  //     teachers[0]?.teacher_name && setTeachers(teachers);
  //     setLoading(false);
  //   }

  //   if (programId > 0) {
  //     getProgramById(programId);

  //     setLoadingProgram(false);
  //   }
  // }, [loadingProgram])

  const formatDate = (date, separator = '/') => {
    return date?.substring(0,10).split('-').reverse().join(separator);
  }

  // const dateStartProgram = program?.program_start_date?.substring(0,10);
  // const dateEndProgram = program?.program_end_date?.substring(0,10);
  const dateStartProgram = '2023-06-01';
  const dateEndProgram = '2023-12-15';

  const dateStart = new Date(dateStartProgram);
  const dateEnd = new Date(dateEndProgram);
  const dateNow = new Date();

  const total = 100;
  const Difference_In_Time = dateEnd.getTime() - dateStart.getTime();
  const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

  const Difference_In_Time_Used = dateNow.getTime() - dateStart.getTime();
  const Difference_In_Days_Used = Difference_In_Time_Used / (1000 * 3600 * 24);

  const totalDaysProgram = Difference_In_Days;
  const daysUsedProgram = Difference_In_Days_Used;

  const percentUsed = dateStart > dateNow ? 0 : (daysUsedProgram / totalDaysProgram) * 100;

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Text 
        fontSize={25}
        fontWeight={500}
        pl='15px'
      >Olá {dataUser?.user_name},</Text>
      <Text 
        fontSize={18}
        pl='15px' 
        mb='25px'
      >
        seja bem-vindo(a) ao espaço das empresas.
      </Text>

      <Banner
        data={program}
        dateStartProgram={formatDate(dateStartProgram)}
        dateEndProgram={formatDate(dateEndProgram)}
      />

      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 4, "2xl": 4 }}
        gap='20px'
        mb='20px'
        mt='20px'
        >
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={FaUsers} color={brandColor} />
              }
            />
          }
          name='Em formação'
          value='108'
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdSchool} color={brandColor} />
              }
            />
          }
          name='Formados'
          value='0'
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={FaUsers} color={brandColor} />
              }
            />
          }
          name='Indicados'
          value='0'
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdOutlineWork} color={brandColor} />
              }
            />
          }
          name='Contratados'
          value='0'
        />
      </SimpleGrid>

      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='20px' mt='20px' mb='20px'>
        <Card px='0px'>
          <Box 
            w='100%' 
            mt='auto'
            px='22px'
            pb='20px'
          >
            <Text color={textColor} fontSize='xl' fontWeight='600' mb='0px'>
              Progresso
            </Text>
            <Text color={textColor} 
              style={{"color": "#A0AEC0"}}
              fontSize='13px' 
              fontWeight='500' 
              mb='20px'
            >
              {/* {formatDate(program?.program_start_date)} a {formatDate(program?.program_end_date)} */}
              {formatDate(dateStartProgram)} a {formatDate(dateEndProgram)}
            </Text>
            <Flex w='100%' justify='space-between' mb='10px'>
              <Text color={textColorSecondary} fontSize='sm' maxW='40%'>
                {percentUsed.toFixed(0)} %
              </Text>
              <Text color={textColorSecondary} fontSize='sm' maxW='40%'>
                {total} %
              </Text>
            </Flex>
            <Progress
              align='start'
              // colorScheme='green'
              colorScheme='brandScheme'
              value={(percentUsed / total) * 100}
              // value={100}
              w='100%'
            />  
          </Box>
        </Card>
        </SimpleGrid>

      {/* <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>
        <DailyTraffic />
        <DailyTrafficAge />
      </SimpleGrid>

      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>
        <PieCardGender />
        <PieCardPCD />
      </SimpleGrid>

      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='20px' mb='20px'>
        <DailyTrafficFormation />
      </SimpleGrid> */}

      <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
        <CheckTable columnsData={columnsDataCheck} tableData={tableDataCheck} />
        <Cronogram
          columnsData={columnsDataCronogram}
          tableData={tableDataCronogram}
        />
      </SimpleGrid>

      <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
        <ComplexTable
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
        />
        <ComplexTableProjects 
          columnsData={columnsDataProjects}
          tableData={tableDataProjects}
        />
      </SimpleGrid>

    </Box>
  );
}
