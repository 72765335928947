import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdHome,
  MdLogout,
  MdLineWeight,
  MdPeople,
  MdSchool,
  MdStackedBarChart,
} from "react-icons/md";
import { BsFillPeopleFill } from "react-icons/bs";
import { FaMedal, FaBuilding, FaThLarge } from "react-icons/fa";
import { GiTeacher } from "react-icons/gi";
import { TbIdBadge2 } from "react-icons/tb";

// Admin Imports
import MainDashboard from "views/admin/default";
import Programas from "views/admin/programas";
import Programa from "views/admin/programa";
import ClassLeaders from "views/admin/classLeaders";
import Companies from "views/admin/companies";
import Teachers from "views/admin/teachers";
import Profile from "views/admin/profile";
import Students from "views/admin/students";
import Ranking from "views/admin/ranking";

// Auth Imports
import SignInCentered from "views/auth/signIn";
import SignIn from "views/auth/signIn/index.jsx";
import SignUp from "views/auth/signUp/index.jsx";
import SignOut from "views/auth/signOut/index.jsx";


const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdBarChart} width='22px' height='22px' color='inherit' />,
    component: MainDashboard,
    hide: false
  },
  {
    name: "Signin",
    layout: "/auth",
    path: "/sign-in",
    icon: (
      <Icon as={MdHome} width='16px' height='16px' color='inherit' />
    ),
    component: SignIn,
    hide: true
  },
  {
    name: "Signout",
    layout: "/auth",
    path: "/sign-out",
    icon: (
      <Icon as={MdHome} width='16px' height='16px' color='inherit' />
    ),
    component: SignOut,
    hide: true
  },
];

export const Logout = [
  {
    name: "Sair",
    layout: "/auth",
    path: "/sign-out",
    icon: (
      <Icon as={MdLogout} width='22px' height='22px' color='inherit' />
    ),
    component: SignOut,
    hide: false
  }
];
export default routes;
